.active {
  background-color: white;
  color: #1A2E35;
}
.inactive{
  color: rgba(75, 70, 92 , 0.8);
  font-size: "15px"
}
#navlink {
  padding: 8px 3px;
  text-decoration: none;
  margin: 3px 10px;
  border-radius: 6px;
  text-transform: none;
  padding: 2px 10px;

}
#navlink:hover {
  background-color: white;
}

#navlink:focus {
  background-color: white;
}
