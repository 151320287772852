body {
  margin: 0;
  display: flex;
  place-items: center;
  min-height: 100vh;
}
#root{
  width: 100%;
  min-height: 100vh;
}
