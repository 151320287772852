.selected-button-en{
background:linear-gradient(90deg, #EC1D23 0%, #EC1D23 0%, #871621 100%); 
font-size: 14px;
height: 50px;
border : 2px solid black;    
font-weight: bold;

color: white;
}
.selected-button-es{
    font-weight: bold;

    background:linear-gradient(90deg, #EC1D23 0%, #EC1D23 0%, #871621 100%); 
    font-size: 14px;
height: 50px;
border : 2px solid black;    
color: white;

}
.selected-button-fr{
    background:linear-gradient(90deg, #EC1D23 0%, #EC1D23 0%, #871621 100%);font-size: 14px;
    height: 50px;
    border : 2px solid black;    
    font-weight: bold;

    color: white;

}
.button-en{
font-size: 14px;
height: 50px;
border : 2px solid black;    
background-color: white;

font-weight: bold;
color: black;

}
.button-es{
    font-weight: bold;

    border : 2px solid black;    
    font-size: 14px;
background-color: white;
color: black;

height: 50px;
}
.button-fr{
    border : 2px solid black;    
    background-color: white;
    font-weight: bold;
color: black;
font-size: 14px;
height: 50px;
}
@media screen and (max-width: 330px){
    .selected-button-en{
        padding: 5px 5px;
        }
        .selected-button-es{
            padding: 5px 5px;
        
        }
        .selected-button-fr{
            padding: 5px 5px;
        }
        .button-en{
            padding: 5px 5px;
        }
        .button-es{
            padding: 5px 5px;
        }
        .button-fr{
            padding: 5px 5px;
        }
  }
@media screen and (max-width: 360px ) and ( min-width:330px){
    .selected-button-en{
        padding: 5px 7px;
        }
        .selected-button-es{
            padding: 5px 7px;
        
        }
        .selected-button-fr{
            padding: 5px 10px;
        }
        .button-en{
            padding: 5px 7px;
        }
        .button-es{
            padding: 5px 7px;
        }
        .button-fr{
            padding: 5px 10px;
        }
  }
  @media screen and (min-width: 360px){
    .selected-button-en{
        padding: 5px 15px;
        }
        .selected-button-es{
            padding: 5px 10px;
        
        }
        .selected-button-fr{
            padding: 5px 15px;
        }
        .button-en{
            padding: 5px 15px;
        }
        .button-es{
            padding: 5px 10px;
        }
        .button-fr{
            padding: 5px 15px;
        }
  }